<template>
  <ul class="flex justify-center w-full steps">
    <li v-for="(item, i) in items" :key="i" class="steps__item" :class="{ 'steps__item--done': i < activeIndex }">
      <Button ref="items" :url="item.allowed === false ? '' : item.url">
        <span class="steps__number">
          <span>{{ i + 1 }}</span>

          <Icon v-if="i < activeIndex" :width="16" :height="16" icon="check" class="steps__check" />
        </span>
        <span class="steps__label">{{ item.label }}</span>
      </Button>
    </li>
  </ul>
</template>

<script>
import { Icon } from "cavea-design-system";

export default {
  name: "Steps",

  components: {
    Button: () => import("@/components/Button"),
    Icon,
  },

  props: {
    items: {
      type: [Object, Array],
      default: null,
    },
  },

  data() {
    return {
      activeIndex: 0,
    };
  },

  watch: {
    $route() {
      setTimeout(() => {
        this.setActiveIndex();
      });
    },
  },

  mounted() {
    this.setActiveIndex();
  },

  methods: {
    setActiveIndex() {
      const { items } = this.$refs;

      for (let i = 0; i < items?.length; i++) {
        const item = items[i].$el;
        const isActive = item.classList.contains("router-link-exact-active");

        if (isActive) {
          this.activeIndex = i;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/colors";

.steps__item {
  display: flex;
  margin: 0 2rem;
  align-items: center;

  .router-link-exact-active {
    .steps__number {
      border: 1px solid color(white, base);
      color: color(white, base);
      background-color: color(cta, primary);
    }
  }

  .button {
    font-size: 1rem;
  }
}

.steps__item--done {
  .steps__number {
    background-color: color(signal, green);
    color: color(white, base);

    > span {
      transform: translate(-50%, 250%);
    }

    .icon {
      transform: translate(-50%, -50%);
    }
  }
}

.steps__number {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: transparent;
  border: 1px solid color(border, base);
  display: flex;
  overflow: hidden;
  line-height: 1;
  align-items: center;
  justify-content: center;
  transition: var(--transition-out);

  > span,
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out;
  }

  .icon {
    transform: translate(-50%, -250%);
  }
}
</style>
